export const encryptionKeys = {
  IV: '9MSkKLOhJcWselB5',
  key: 'pLpT4d8jvKQJq'
};

export const userRole = {
  Admin: 1,
  SubAdmin: 2
};

export const NotificationType = {
  BROADCAST: 1,
  EMAIL: 2
};

export const NotificationUserType = {
  allUser : 1,
  individualUser : 2
};

export const PromotionType = {
  Brand: 1,
  Offer: 2,
  None: 3
};